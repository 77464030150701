.abhyasi-header {
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-weight: 300;
  background-color: #d6eaf8;
}
.form-page {
  background-color: #d6eaf8;
  margin-top: 3%;
}
.sub-btn {
  width: 27%;
  height: 35px;
  background-color: blue;
  color: white;
  margin-top: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
.select-field {
  width: 91.2% !important;
  height: 36px !important;
  font-size: 13px;
}
.selectIcons {
  background-color: #e9eaeb;
  position: relative;
  top: 1px;
  padding: 10px;
  text-align: center;
  width: 30px;
}
.icons {
  background-color: #e9eaeb;
  padding: 10px;
  text-align: center;
  width: 30px;
  top: 2px;
  left: 0px;
  position: relative;
}
.note {
  font-size: 14px;
}
input.input-field {
  width: 91.2%;
  height: 34px;
  border: none;
  border-radius: 5px;
  padding-left: 9px;
}
.formnote {
  font-size: 12.8px;
  width: 80%;
}
.email {
  color: blue;
  text-decoration: none;
}
.textarea {
  width: 99%;
  height: 34px;
  border: none;
  border-radius: 5px;
}

.required::after {
  content: " *";
  color: red;
}
.errorMsg {
  color: red;
  font-size: 12px;
}
.form-body {
  margin-top: 1%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  border: 1px solid;
  border: none;
  width: 750px;
}
.form-footer {
  padding: 13px;
  font-size: 11px;
  text-align: center;
  color: grey;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
