.thermal_receipt {
  max-width: 100px;
  font-size: 3px !important;
  font-weight: bold !important;
  font-family: Arial, Helvetica, sans-serif !important;
  word-wrap: break-word !important;
}
.donation-br {
  line-height: 10px;
  margin: 0px 0px 5px 0px;
}
.print-info {
  line-height: 5px;
  font-size: 2px;
}
@media print {
  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
}
