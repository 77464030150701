$primary     : #1998b5;
$info        : #7239ea;
$secondary   : #e9e9ee;
$success     : #50cd89;
$danger      : #f1416c;
$warning     : #ffc700;
$bcolor      : #2F80ED;
$focus       : #0047FF;
$bg          : #f4f7ff;
$placeholder : #bac3cc;

$color : #3f4254;

$border-color : #eff2f5;

$primaryHover   : #0c758d;
$infoHover      : #5014d0;
$secondaryHover : #d7d7db;
$successHover   : #47be7d;
$dangerHover    : #d9214e;
$warningHover   : #ffc700;
